import { useEffect, useState, useRef } from "react";
import './Main.scss';
import { List } from '../list/List';
import { NavOption } from '../../models/NavOption.model';
import { Nav } from '../nav/Nav';
import { db, upload } from '../../config/firebase';
import { getDocs, collection, query, orderBy } from "firebase/firestore";
import Movie from "../../models/Movie.model";
import { Search } from '../search/Search';
import { Loader } from '../loader/Loader';

type Props = { 
  loading: boolean;
  setLoading: Function;
};

export const Main = ({ loading, setLoading }: Props) => {
  const [selectedTab, setSelectedTab] = useState(NavOption.Collection);
  const [data, setData] = useState([]);
  const [term, setTerm] = useState('');
  const [collectionData, setCollectionData] = useState([]);
  const [wishListData, setWishListData] = useState([]);
  
  const dvdCollection = collection(db, "dvd-collection");

  const isMounted = useRef(false);

  const getCollection = async (): Promise<void> => {
    setLoading(true)
    
    try {
      const data = await getDocs(query(dvdCollection, orderBy('qTitle')));
      // const data = {docs: []}
      const collection: Movie[] = [];
      const wishList: Movie[] = [];

      data.docs.forEach((doc) => {
        const movie: Movie = {
          title: doc.data().title,
          qTitle: doc.data().qTitle,
          year: doc.data().year ?? null,
          wishList: doc.data().wishList,
          isDisney: doc.data().isDisney,
          id: doc.id
        };

        if (movie.wishList) {
          wishList.push(movie)
        } else {
          collection.push(movie)
        }
     }); 
     
      setCollectionData(collection);
      setWishListData(wishList);
      setData(collection);
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    } catch (e) {
      console.error(e)
      setLoading(false)
    }
  }

  function toggleDataList (): void {
    if (selectedTab === NavOption.WishList) {
      setData(wishListData)
    } else {
      setData(collectionData)
    }
  }

  const searchCollection = (term: string) => {
      const allData = [...collectionData, ...wishListData];
      const results = allData.filter((movie) => movie.qTitle.toLowerCase().includes(term));
      setData(results);
  }

  useEffect(() => {
    if (isMounted.current) {
      if (term === '') {
        toggleDataList();
        return;
      }
    
      if (term.length > 2) {
        searchCollection(term.toLowerCase());
      }
    }
  }, [term]);

  useEffect(() => {
    if (isMounted.current) {
      toggleDataList();
    } 
  }, [selectedTab])

  useEffect(() => {
    isMounted.current = true;
    getCollection();
  }, []);

  const main = useRef(null);

  return (
    <main id="main" className={`main ${loading && 'loading'}`} ref={main}>
    {/* <button onClick={upload}>Upload</button> */}
        {!loading &&
        <>
          <Search term={term} setTerm={setTerm}/>
          <Nav setSelectedTab={setSelectedTab} getCollection={getCollection} loading={loading} selectedTab={selectedTab} resultCount={data.length}/>
          <div className="content-container">
            <List loading={loading} data={data} selectedTab={selectedTab} />
          </div>
        </>
        }
        {( main && loading ) && <Loader app={main} />}
    </main>
  )
}
